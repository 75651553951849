.vehicle-screen-alert {
  width: 30%;
}

.vehicle-screen-alert > div {
  font-size: 12px;
  padding: 5px;
  color: black;
  font-weight: bold;
}

.vehicle-screen-container {
  display: flex;
  height: calc(100vh - 170px);
  width: 100%;
  padding: 10px;
}

.vehicle-screen-container > .vehicles-screen-table-container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: calc(80vh - 45px);
  width: 40%;
}

.vehicle-screen-container >
.vehicles-screen-table-container >
.vehicles-excel-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.vehicle-screen-container > .vehicles-screen-table-container > .table {
  display: flex;
  overflow: auto;
}

.vehicle-screen-container > .vehicles-screen-form {
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  margin-right: 12px;
  width: 60%;
  height: calc(80vh - 70px);
  min-width: 360px;
  padding: 0 60px 0 0;
}

.vehicles-screen-form > .form-section-one {
  width: 30%;
}

.vehicles-screen-form > .form-section-two {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.vehicles-screen-form > .form-section-one > .form-section-one-state {
  margin-top: 16px;
}

.vehicles-screen-form > .form-section-two, label, p {
  font-size: 12px;
}

.vehicles-screen-form > .form-section-two > div {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.vehicles-screen-form > .form-section-two > div > label {
  display: inline-block;
  width: 30%;
  text-align: end;
  margin-right: 12px;
  margin-bottom: 0;
}

.vehicles-screen-form > .form-section-two > div > .dropdown-container,
.vehicles-screen-form > .form-section-two > div > .input {
  flex: 1;
  width: 1px;
}

.vehicles-screen-form > .form-section-two > div > .tanks-dropdowns,
.vehicles-screen-form > .form-section-two > div > .tanks-inputs {
  flex: 1;
  display: flex;
}

.vehicles-screen-form > .form-section-two > div > .tanks-dropdowns > .dropdown:first-child,
.vehicles-screen-form > .form-section-two > div > .tanks-inputs > .input:first-child {
  margin-right: 5px;
}

.vehicles-screen-form > .form-section-two > div > .tanks-dropdowns > .dropdown,
.vehicles-screen-form > .form-section-two > div > .tanks-inputs > .input {
  min-width: 0;
  width: 50%;
}

.vehicles-screen-form > .form-section-two > div > .dropdown-container > .section-dropdown {
  width: 100%;
}

.vehicles-screen-form > .form-section-two > .vehicle-form-buttons {
  margin-top: 18px;
  justify-content: end;
}

.vehicles-screen-form > .form-section-two > .vehicle-form-buttons > button {
  font-size: 12px;
  margin-left: 10px;
  margin-right: 0;
}

@media (max-width: 1000px) {
  .vehicles-screen-form > .form-section-one .c-image-container {
    width: 30% !important;
  }

  .vehicle-screen-container {
    flex-direction: column;
  }

  .vehicle-screen-container > .vehicles-screen-form {
    margin-bottom: 30px;
  }

  .vehicle-screen-container > .vehicles-screen-table-container {
    height: 800px;
  }

  .vehicle-screen-container > .vehicles-screen-form {
    flex-direction: column;
    overflow-y: scroll;
    height: auto;
  }

  .vehicle-screen-container > .vehicles-screen-table-container,
  .vehicle-screen-container > .vehicles-screen-form {
    width: 100%;
  }

  .vehicles-screen-form > .form-section-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }

  .vehicles-screen-form > .form-section-two {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .vehicle-screen-alert {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .vehicles-screen-form > .form-section-one .c-image-container {
    width: 50% !important;
  }
}
