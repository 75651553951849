.screen-authorized-stays-log > div {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.screen-authorized-stays-log > div > .search-bar {
  display: flex;
  margin-bottom: 10px;
}

.screen-authorized-stays-log > div > .search-bar > .form-field {
  margin-right: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.screen-authorized-stays-log > div > .search-bar > .form-field > label {
  margin: 0px;
  margin-right: 5px;
}

.screen-authorized-stays-log > div > .search-bar > .form-field > #c-plants-dropdown {
  width: 300px;
  font-size: 11px;
  height: 31px;
}

.screen-authorized-stays-log > div > .search-bar > button {
  font-size: 12px;
  height: 31px;
}

.authorized-stays-log-modal {
  margin-top: 20px;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.authorized-stays-log-modal > .form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.authorized-stays-log-modal > .form-field > label:first-child {
  width: 170px;
  text-align: right;
  font-weight: bold;
  margin-right: 5px;
}

.authorized-stays-log-modal > .form-field > label:last-child {
  flex: 1;
  background-color: #eee;
  padding: 4px 5px;
  border-radius: 3px;
}
