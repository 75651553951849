.screen-tanks > .header {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.screen-tanks > .header > .download-button-container {
  margin-right: 13px;
  align-self: center;
}

.screen-tanks > .content {
  display: flex;
}

.screen-tanks > .content > .form,
.screen-tanks > .content > .table {
  flex: 1;
}

.screen-tanks > .content > .form {
  display: flex;
}
.screen-tanks > .content > .form > .first-container {
  width: 340px;
}

.screen-tanks > .content > .form > .first-container > .tank-photo-container {
  justify-content: center;
}

.screen-tanks > .content > .form > .first-container > .tank-photo-container > .c-image-container > img {
  height: 318px;
  width: 318px;
}

.screen-tanks > .content > .form > .first-container > .tank-status-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.screen-tanks > .content > .form > .second-container {
  margin: 0px 10px;
  flex: 1;
}

.screen-tanks > .content > .form > .second-container > .form-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.screen-tanks > .content > .form > .second-container > .form-field > label {
  width: 110px;
  text-align: right;
  margin: 0;
  margin-right: 5px;
}

.screen-tanks > .content > .form > .second-container > .form-field > label + * {
  flex: 1;
  font-size: 12px;
  margin: 0px;
}

.screen-tanks > .content > .form > .second-container > .form-actions {
  margin-top: 10px;
  display: flex;
  justify-content: right;
}

.screen-tanks > .content > .form > .second-container > .form-actions > .button {
  font-size: 12px;
}

.screen-tanks > .content > .table {
  display: flex;
  margin-left: 10px;
  width: 97%;
  max-height: calc(100vh - 150px);
  overflow: auto;
  margin-top: 0px;
}

@media only screen and (max-width: 1200px) {
  .screen-tanks > .content > .form > .first-container {
    width: 200px;
  }

  .screen-tanks > .content > .form > .first-container > .tank-photo-container > .c-image-container > img {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 800px) {
  .screen-tanks > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .screen-tanks > .content > .form {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .screen-tanks > .content > .form > .first-container {
    margin: auto;
    margin-bottom: 10px;
  }

  .screen-tanks > .content > .table {
    max-height: 400px;
  }
}
