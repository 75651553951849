.screen-parked-units {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 120px);
  overflow: hidden;
}

.screen-parked-units > div {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 700px;
  margin-right: 10px;
}

.screen-parked-units > div > .search-bar {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.screen-parked-units > div > .search-bar > .form-field {
  display: flex;
  align-items: center;
  margin-right: 10px;
  flex: 1;
}

.screen-parked-units > div > .search-bar > .form-field:last-child {
  margin: 0;
}

.screen-parked-units > div > .search-bar > .form-field > label {
  margin: 0;
  margin-right: 5px;
}

.screen-parked-units > div > .search-bar > .form-field > #c-plants-dropdown,
.screen-parked-units > div > .search-bar > .form-field > .dropdown {
  font-size: 11px;
  flex: 1;
}

.screen-parked-units > div > .table-container {
  height: calc(100vh - 150px);
  overflow: hidden;
}

.screen-parked-units > div > .table-container > .resume {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}

.screen-parked-units > div > .table-container > .resume > label {
  margin: 0;
}

.screen-parked-units > div > .table-container > .resume > label > b {
  margin-right: 5px;
}

.screen-parked-units > div > .table-container > div > .table-responsive {
  height: calc(100vh - 220px)
}

.screen-parked-units > div > .table-container > div > .table-responsive > .table > tbody > tr > td > .link,
.parked-units-checklists-modal > div > .table-responsive > table > tbody > tr > td > .link {
  text-decoration: underline;
}

.screen-parked-units > form {
  margin-top: 81px;
  overflow-y: auto;
  width: 600px;
  position: relative;
}

.screen-parked-units > form > i {
  position: absolute;
  top: 15px;
  right: 15px;
  display: none;
}

.screen-parked-units > form > .form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.screen-parked-units > form > .form-field > label{
  margin: 0;
}

.screen-parked-units > form > .form-field > label:first-child {
  width: 100px;
  text-align: right;
  margin-right: 5px;
}

.screen-parked-units > form > .form-field > label:nth-child(2) {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 5px 10px;
  height: 30px;
  overflow: hidden;
  color: dimgray;
}

.screen-parked-units > form > .form-field > textarea {
  flex: 1;
}

.screen-parked-units > form > .form-field.textarea-field > label {
  align-self: flex-start;
  margin-top: 5px;
}

.screen-parked-units > form > .form-field > button {
  font-size: 12px;
  text-decoration: underline;
  margin-left: 5px;
}

.screen-parked-units > form > footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.screen-parked-units > form > footer > button.button {
  font-size: 12px;
}

@media only screen and (max-width: 1600px) {
  .screen-parked-units > form {
    width: 500px;
  }
}

@media only screen and (max-width: 1250px) {
  .screen-parked-units > form {
    width: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  .screen-parked-units > form > i {
    display: inline;
  }

  .screen-parked-units > form {
    background-color: white;
    position: fixed !important;
    right: 0;
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.3);
    padding: 20px;
    padding-top: 50px;
    width: 500px;
  }
}
