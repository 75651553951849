.screen-freights-consult {
  display: flex;
  flex-direction: column;
}

.screen-freights-consult > .title {
  margin-top: 10px;
  margin-bottom: 10px;
}

.screen-freights-consult > .search-bar > div > div > div,
.screen-freights-consult > .search-bar > div > div > .title {
  margin-right: 7px;
}

.screen-freights-consult > .search-bar,
.screen-freights-consult > .search-bar > .filters,
.screen-freights-consult > .search-bar > .filters > .origin-container,
.screen-freights-consult > .search-bar > .filters > .client-container,
.screen-freights-consult > .search-bar > .actions,
.screen-freights-consult > .search-bar > .actions > .action-filters,
.screen-freights-consult > .search-bar > .actions > .action-filters > .is-internal,
.screen-freights-consult > .search-bar > .actions > .excel > .excel-container {
  display: flex;
  align-items: center;
}

.screen-freights-consult > .search-bar > .actions {
  justify-content: space-between;
  width: calc(100vw - 580px);
}

.screen-freights-consult > .search-bar > .filters > .origin-container > .search,
.screen-freights-consult > .search-bar > .filters > .client-container > .value,
.screen-freights-consult > .search-bar > .filters > .client-container > .search {
  font-size: 11px;
  width: 220px;
  height: 32px;
  padding: 10px;
}

.screen-freights-consult > .search-bar > div > div > .title,
.screen-freights-consult > .search-bar > .actions > .action-filters > .is-internal > label {
  margin-bottom: 0px;
}

.screen-freights-consult > .search-bar > .actions > .action-filters > .is-internal > .value {
  margin-left: 5px;
}

.screen-freights-consult > .search-bar > .actions > .action-filters > .search-container > button {
  font-size: 12px;
  border-radius: 5px;
}

.screen-freights-consult > .excel-container > button {
  border-radius: 5px;
}

.screen-freights-consult > .content {
  margin-top: 10px;
}

.screen-freights-consult > .content > .table-container > div {
  min-height: 200px;
  max-height: calc(100vh - 180px);
}

@media only screen and (max-width: 940px) {
  .screen-freights-consult > .content > .table-container > div {
    max-height: calc(100vh - 220px);
  }

  .screen-freights-consult > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .screen-freights-consult > .search-bar > .filters,
  .screen-freights-consult > .search-bar > .actions {
    justify-content: space-between;
    width: 100%;
  }

  .screen-freights-consult > .search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .screen-freights-consult > .search-bar > div {
    margin-bottom: 5px;
  }
}
