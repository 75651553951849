.screen-freights {
  display: flex;
  flex-direction: column;
}

.screen-freights > .bar {
  display: flex;
}

.screen-freights > .bar > .form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.screen-freights > .bar > .form-field > label {
  margin: 0;
  margin-right: 5px;
}

.screen-freights > .bar > .form-field > label + * {
  font-size: 12px;
}

.screen-freights > .bar > .is-internal {
  margin-left: 15px;
}

.screen-freights > .bar > .is-internal > .checkbox,
.screen-freights > .bar > .is-internal > .checkbox > label {
  margin-left: 2px;
  margin-top: 5px;
}

.screen-freights > .content > .section-one,
.screen-freights > .content > .section-two {
  width: 50%;
}

.screen-freights > .content > .section-one > h6 {
  border-bottom: 2px solid var(--primary);
  padding: 5px;
  font-weight: bold;
}

.screen-freights > .content > .section-one > .form {
  display: flex;
  justify-content: space-around;
}

.screen-freights > .content > .section-one > .form > .left-container,
.screen-freights > .content > .section-one > .form > .right-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 50%;
}

.screen-freights > .content > .section-one > .form > .left-container > .form-field,
.screen-freights > .content > .section-one > .form > .right-container > .form-field {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.screen-freights > .content > .section-one > .form > .left-container > .form-field > label,
.screen-freights > .content > .section-one > .form > .right-container > .form-field > label {
  width: 150px;
  margin: 0;
  margin-right: 5px;
  text-align: right;
  align-self: center;
}

.screen-freights > .content > .section-one > .form > .left-container > .form-field > label + *,
.screen-freights > .content > .section-one > .form > .right-container > .form-field > label + * {
  flex: 1;
  font-size: 12px;
}

.screen-freights > .content > .section-one > .form > .left-container > .evidence-container > .pdf-button #c-pdf {
  display: none
}

.screen-freights > .content > .section-one > .form > .left-container > .evidence-container > .pdf-button {
  height: 35px;
  width: 244px;
  display: flex;
  align-items: center;
}

.screen-freights > .content > .section-one > .form > .left-container > .evidence-container > .pdf-button > label {
  padding: 5px;
  background-color: var(--primary);
  color: white;
  text-align: center;
  cursor: pointer;
  margin: 0;
  width: 100px;
  border-radius: 4px;
}

.screen-freights > .content > .section-one > .form > .left-container > .evidence-container > .pdf-button > .checkbox {
  margin-top: 5px;
  margin-left: 10px;
}

.screen-freights > .content > .section-one > .action-buttons {
  display: flex;
  justify-content: right;
}

.screen-freights > .content > .section-one > .action-buttons > div > button {
  font-size: 12px;
}

.screen-freights > .content > .section-two {
  margin-top: 15px;
}

.screen-freights > .content > .section-two > .table-header {
  display: flex;
  justify-content: space-between;
}

.screen-freights > .content > .section-two > .table-header {
  border-top: 2px solid var(--primary);
}

.screen-freights > .content > .section-two > .table-header > h6 {
  padding: 5px;
  font-weight: bold;
  margin: 0px;
}

.screen-freights > .content > .section-two > .table-header > .expiration-container {
  display: flex;
  align-items: center;
}

.screen-freights > .content > .section-two > .table-header > .expiration-container > label{
  margin: 0;
  margin-right: 5px;
}

.screen-freights > .content > .section-two > .table-container > div > .table-responsive {
  min-height: 200px;
  max-height: calc(100vh - 570px);
  overflow: auto;
  margin-top: 0px;
}

@media only screen and (min-width: 1380px) {
  .screen-freights > .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .screen-freights > .content > .section-two {
    width: calc(100vw - 730px);
  }

  .screen-freights > .content > .section-two {
    margin-top: 27px;
    margin-left: 30px;
  }

  .screen-freights > .content > .section-two > .table-container > div > .table-responsive {
    max-height: calc(100vh - 280px);
    overflow: auto;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 1380px) {
  .screen-freights > .content > .section-one,
  .screen-freights > .content > .section-two {
    width: 100%;
  }
}

@media only screen and (max-width: 780px) {
  .screen-freights > .content > .section-one > .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .screen-freights > .content > .section-one > .form,
  .screen-freights > .content > .section-two > .form {
    justify-content: left;
    align-items: flex-start;
  }

  .screen-freights > .content > .section-two > .table-container > div > .table-responsive {
    max-height: 400px;
    overflow: auto;
  }

  .screen-freights > .content > .section-one > .form > .left-container,
  .screen-freights > .content > .section-one > .form > .right-container {
    max-width: 1500px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .screen-freights > .bar {
    flex-direction: column;
  }
}
